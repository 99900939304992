<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <v-dialog hide-overlay fullscreen v-model="visible">
    <v-fab-transition>
      <v-btn
        class="close-button mr-2 mt-4"
        color="white"
        style="opacity: 85%"
        small
        dark
        top
        right
        fixed
        fab
        @click="$emit('closeDialog')"
      >
        <v-icon color="black">mdi-close</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-fab-transition>
      <v-btn
        class="close-button ml-4 mb-8"
        color="white"
        style="opacity: 85%"
        small
        dark
        bottom
        left
        fixed
        fab
        @click="shareImage"
      >
        <v-icon color="black">mdi-share-variant</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- For Touch Screens -->
    <v-carousel v-model="currentImage" v-if="isMobile()" height="100%" :show-arrows="carouselImages.length > 1" touchless>
      <v-carousel-item v-for="(image, i) in carouselImages" :key="i">
        <div style="height: 100%; background: black; display:flex; align-items: center;">
          <PinchZoom>
            <v-img :src="image.image"></v-img>
          </PinchZoom>
        </div>
      </v-carousel-item>
    </v-carousel>

    <!-- For desktops -->
    <v-carousel v-model="currentImage" v-else height="100%" :show-arrows="carouselImages.length > 1">
      <v-carousel-item v-for="(image, i) in carouselImages" :key="i">
        <v-row class="ma-0 pa-0" style="height:100%; background: black; overflow: auto;" align="center" justify="center">
          <v-col class="ma-0 pa-0" :cols="zoomSize">
            <v-img :src="image.image"></v-img>
          </v-col>
        </v-row>
      </v-carousel-item>
      <div style="position:fixed; bottom:50px; right:50px;">
        <v-btn class="mr-1" width
          @click="()=>{zoomSize = Math.min(12, zoomSize+1)}"
          :disabled="zoomSize == 12"
        >
          <v-icon color="white" >mdi-magnify-plus-outline</v-icon>
        </v-btn>
        <v-btn @click="()=>{zoomSize = Math.max(4, zoomSize-1)}"
          :disabled="zoomSize == 4"
        >
          <v-icon>mdi-magnify-minus-outline</v-icon>
        </v-btn>
      </div>
    </v-carousel>
  </v-dialog>
</template>
<script>
import PinchZoom from 'vue-pinch-zoom'
import Mixins from "@utils/mixins";
import { FileOpener } from "@ionic-native/file-opener";

export default {
  name: "FullScreenImageCarousel",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PinchZoom,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initialImage: {
      type: Object,
      default: () => null,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      zoomSize: 4,
      carouselImages: [],
      currentImage: null,
    };
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        return true
      else
        return false
    },

    async downloadImg(url, label){
      const downloadEssentials = {
        id: this.Helper.generateUID(),
        progres: null,
        file_name: label,
        featureName: "Gallery",
      };
      const downloadedImage = await this.api.download(
        this,
        this.essentials,
        url,
        downloadEssentials,
        "downloads/"
      )
      return downloadedImage
    },

    async openFile(download) {
      FileOpener.open(download.uri, download.type);
    },

    async shareImage(){
      let image = this.carouselImages[this.currentImage]
      let url = image.image + "?"+(new Date()).getTime()
      const downloadedImage = await this.downloadImg(url, image.file_name)
      await this.openFile(downloadedImage)
    }
  },
  watch: {
    visible(v) {
      console.log(v);
      if (v) {
        if (!this.initialImage) {
          this.carouselImages = this.images;
          return;
        }
        this.carouselImages.push(this.initialImage);
        this.images
          .filter((i) => i != this.initialImage)
          .map((i) => this.carouselImages.push(i));
        return;
      }
      this.carouselImages = [];
    },
  },
};
</script>
<style scoped>
.carousel-image-container {
  height: 100%;
  max-width: 100%;
  background-color: black;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>